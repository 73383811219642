<template>
  <v-card>
    <v-card-title>
      <h1>Hosts</h1>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-btn
      :disabled="working"
      :loading="working"
      circle
      absolute
      top
      small
      right
      fab
      color="orange"
      @click="add"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="usersList"
      :pagination.sync="pagination"
      :search="search"
      class="elevation-1"
      item-key="id"
    >
      <template #items="{ item }">
        <tr>
          <td class="text-xs-left">{{ item.firstname }} {{ item.lastname }}</td>
          <td width="250px">
            <v-btn
              :loading="working"
              color="white"
              light
              small
              fab
              @click="edit(item)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              color="white"
              light
              small
              fab
              :loading="working"
              @click="openRemoveDialog(item)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="deleting" :persistent="true" max-width="500px">
      <v-card v-if="!!host">
        <v-toolbar color="highlight" dark>
          <v-toolbar-title> Delete Host</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Are you sure want to delete the host <b>{{ host.firstname }}</b
          >?
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn :disabled="working" flat class="error" @click="remove"
              >Remove</v-btn
            >
            <v-btn :disabled="working" flat class="secondary" @click="cancel">
              Cancel
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editing" persistent width="768px">
      <v-card v-if="host">
        <v-toolbar color="highlight" dark>
          <v-icon dark>edit</v-icon>
          <v-toolbar-title>
            {{ host.firstname }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn :disabled="working" icon @click="update">
            <v-icon>saves</v-icon>
          </v-btn>
          <v-btn :disabled="working" icon @click="cancel">
            <v-icon>cancel</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="host.firstname"
                label="First Name"
                required
              />
            </v-flex>

            <v-flex xs12 sm6>
              <v-text-field
                v-model="host.lastname"
                label="Last Name"
                required
              />
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="host.role" label="Role" required />
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="email" label="Email" required />
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="host.venmo"
                label="Venmo QR URL"
                required
              />
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="host.paypal"
                label="PayPal hostname"
                required
              />
            </v-flex>
            <v-flex v-if="lastKnownGame" xs12 sm6>
              Last Know Game: {{ lastKnownGame }}
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="host.image" label="Image Url" required />
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                required
                :items="orgsList"
                v-model="host.orgID"
                label="Org"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="user.super" xs12 sm6>
              <v-checkbox
                v-model="host.super"
                dense
                label="Super Admin"
                hide-details
              />
            </v-flex>
            <template v-if="adding">
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="password"
                  autocomplete="new-password"
                  type="password"
                  placeholder="PASSWORD"
                  required
                  minlength="8"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="confirmPassword"
                  autocomplete="new-password"
                  type="password"
                  placeholder="CONFIRM PASSWORD"
                  required
                  minlength="8"
                />
              </v-flex>
            </template>
          </v-layout>
        </v-container>

        <v-card-actions v-if="adding && !passwordMatch">
          <v-layout justify-center> PASSWORD IS REQUIRED </v-layout>
        </v-card-actions>
        <v-card-actions v-else-if="adding && !host.orgID">
          <v-layout justify-center> ORG ID IS REQUIRED </v-layout>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer />
          <v-btn :disabled="working" flat class="primary" @click="update">
            Save
          </v-btn>
          <v-btn :disabled="working" flat class="secondary" @click="cancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import firebase from "firebase/app"
import { mapActions, mapGetters } from "vuex"
import { Role } from "@/helpers"
import { DEFAULT_IMAGE_PROFILE_URL, MIN_PASSWORD_LENGTH } from "@/config"
import {
  fetchUsersByRole,
  fetchUserEmail,
  fetchUserIDByEmail,
  fetchUser
} from "@/services/user.service"
import { fetchGame, fetchOrgs } from "@/services/game.service"

const HEADERS = [
  {
    text: "Name",
    value: "firstname",
    align: "left",
    sortable: true
  },
  {
    text: "Action",
    value: "action",
    align: "left",
    sortable: false
  }
]

const DEFAULT_USER_STATE = {
  firstname: "",
  lastname: "",
  role: Role.Host
}

export default {
  name: "Hosts",
  data() {
    return {
      working: false,
      host: null,
      email: "",
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      password: "",
      confirmPassword: "",
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      search: "",
      headers: HEADERS,
      users: null,
      lastKnownGame: null,
      pagination: { rowsPerPage: 20 }
    }
  },
  computed: {
    ...mapGetters(["orgID", "user"]),
    orgsList() {
      return Object.entries(this.orgs || {}).map(([id, org]) => ({
        name: org.name,
        id
      }))
    },
    usersList() {
      return Object.entries(this.users || {})
        .filter(
          entry => entry[1].originOrgID == this.orgID && !entry[1].deleted
        )
        .map(([id, user]) => ({ ...user, id }))
    },
    passwordMatch() {
      return (
        this.password &&
        this.password.length > MIN_PASSWORD_LENGTH &&
        this.confirmPassword &&
        this.confirmPassword === this.password
      )
    }
  },
  async created() {
    const [users, orgs] = await Promise.all([
      fetchUsersByRole(Role.Host),
      fetchOrgs()
    ])
    this.users = users
    this.orgs = orgs
  },
  methods: {
    ...mapActions("auth", ["signUserUp"]),
    ...mapActions("pregame", ["updateMessage"]),
    async updateUsers() {
      this.users = await fetchUsersByRole(Role.Host)
    },
    cancel() {
      this.email = null
      this.password = null
      this.confirmPassword = null
      this.host = null
      this.deleting = false
      this.editing = false
      this.adding = false
      this.working = false
    },
    openRemoveDialog(user) {
      this.host = { ...user }
      this.deleting = true
    },
    async remove() {
      this.working = true
      await this.$store.dispatch("updateHost", { ...this.host, deleted: true })
      const update = {}
      update[`0/${this.host.id}`] = null
      update[`1/${this.host.id}`] = null
      await firebase.database().ref("access").update(update)
      await this.updateUsers()
      this.cancel()
    },
    async edit(user) {
      this.working = true
      this.host = { ...user }
      this.email = await fetchUserEmail(user.id)
      const game = await fetchGame({
        orgID: user.orgID,
        gameID: user.gameID
      })
      if (game && game.name) {
        this.lastKnownGame = game.name
      }
      this.editing = true
      this.working = false
    },
    add() {
      this.host = { ...DEFAULT_USER_STATE }
      this.editing = true
      this.adding = true
    },
    async update() {
      this.working = true
      try {
        const newUser = {
          ...this.host,
          createdBy: this.user.firstname
        }
        if (this.adding) {
          if (!newUser.orgID)
            throw new Error("Need an org ID to create a new user")
          if (!this.email) throw new Error("Email is required")
          const existingUserID = await fetchUserIDByEmail(this.email)
          if (existingUserID) {
            const user = await fetchUser({ userID: existingUserID })
            if (!user)
              throw new Error(
                `Email ${this.email} is assigned to a deleted user (ID ${existingUserID})`
              )
            if (user.role === Role.Host) {
              throw new Error(
                `Email ${this.email} is assigned to ${user.firstname} ${user.lastname} (ID ${existingUserID})`
              )
            } else {
              if (
                confirm(
                  `Email ${this.email} is assigned to ${user.firstname} ${user.lastname} (ID ${existingUserID}). Would you like to give them "${newUser.role}" permissions?`
                )
              ) {
                await firebase
                  .database()
                  .ref(`access/1/${existingUserID}`)
                  .set(true)
                await this.$store.dispatch("updateHost", {
                  ...user,
                  id: existingUserID,
                  role: newUser.role
                })
                await this.updateUsers()
                this.cancel()
                return
              } else {
                throw new Error("Aborted.")
              }
            }
          }
          const registerUser = {
            firstname: newUser.firstname,
            lastname: newUser.lastname,
            image: newUser.image || DEFAULT_IMAGE_PROFILE_URL,
            email: this.email,
            password: this.password,
            gameID: null,
            clientID: null,
            audit: false,
            trusted: true,
            orgID: newUser.orgID
          }
          const userID = await this.signUserUp(registerUser)
          if (newUser.super) {
            await firebase.database().ref(`access/0/${userID}`).set(true)
          } else {
            await firebase.database().ref(`access/1/${userID}`).set(true)
          }
          await this.$store.dispatch("updateHost", {
            ...newUser,
            id: userID,
            originOrgID: newUser.orgID
          })
        } else {
          const existingUserID = await fetchUserIDByEmail(this.email)
          if (existingUserID !== newUser.id) {
            const user = await fetchUser({ userID: existingUserID })
            if (!user)
              throw new Error(
                `Email ${this.email} is assigned to a deleted user (ID ${existingUserID})`
              )
            throw new Error(
              `Email ${this.email} is already to ${user.firstname} ${user.lastname} (ID ${existingUserID})`
            )
          }
          await this.$store.dispatch("updateHost", newUser)
          await firebase
            .database()
            .ref(`users/private/${newUser.id}/email`)
            .set(this.email)
          if (!newUser.super) {
            const update = {}
            update[`0/${newUser.id}`] = null
            update[`1/${newUser.id}`] = true
            await firebase.database().ref("access").update(update)
          }
        }
        await this.updateUsers()
      } catch (e) {
        console.error(e)
        alert(e.message)
      }
      this.cancel()
    }
  }
}
</script>
