var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("h1", [_vm._v("Hosts")]),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.working,
            loading: _vm.working,
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "orange"
          },
          on: { click: _vm.add }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.usersList,
          pagination: _vm.pagination,
          search: _vm.search,
          "item-key": "id"
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))
                  ]),
                  _c(
                    "td",
                    { attrs: { width: "250px" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.working,
                            color: "white",
                            light: "",
                            small: "",
                            fab: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.edit(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "white",
                            light: "",
                            small: "",
                            fab: "",
                            loading: _vm.working
                          },
                          on: {
                            click: function($event) {
                              return _vm.openRemoveDialog(item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: true, "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          !!_vm.host
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [_c("v-toolbar-title", [_vm._v(" Delete Host")])],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(" Are you sure want to delete the host "),
                    _c("b", [_vm._v(_vm._s(_vm.host.firstname))]),
                    _vm._v("? ")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "error",
                              attrs: { disabled: _vm.working, flat: "" },
                              on: { click: _vm.remove }
                            },
                            [_vm._v("Remove")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: { disabled: _vm.working, flat: "" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "768px" },
          model: {
            value: _vm.editing,
            callback: function($$v) {
              _vm.editing = $$v
            },
            expression: "editing"
          }
        },
        [
          _vm.host
            ? _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.host.firstname) + " ")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.working, icon: "" },
                          on: { click: _vm.update }
                        },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.working, icon: "" },
                          on: { click: _vm.cancel }
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-lg": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "First Name", required: "" },
                                model: {
                                  value: _vm.host.firstname,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "firstname", $$v)
                                  },
                                  expression: "host.firstname"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Last Name", required: "" },
                                model: {
                                  value: _vm.host.lastname,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "lastname", $$v)
                                  },
                                  expression: "host.lastname"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Role", required: "" },
                                model: {
                                  value: _vm.host.role,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "role", $$v)
                                  },
                                  expression: "host.role"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Email", required: "" },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Venmo QR URL", required: "" },
                                model: {
                                  value: _vm.host.venmo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "venmo", $$v)
                                  },
                                  expression: "host.venmo"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "PayPal hostname",
                                  required: ""
                                },
                                model: {
                                  value: _vm.host.paypal,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "paypal", $$v)
                                  },
                                  expression: "host.paypal"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.lastKnownGame
                            ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                _vm._v(
                                  " Last Know Game: " +
                                    _vm._s(_vm.lastKnownGame) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Image Url", required: "" },
                                model: {
                                  value: _vm.host.image,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "image", $$v)
                                  },
                                  expression: "host.image"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  items: _vm.orgsList,
                                  label: "Org",
                                  "item-text": "name",
                                  "item-value": "id"
                                },
                                model: {
                                  value: _vm.host.orgID,
                                  callback: function($$v) {
                                    _vm.$set(_vm.host, "orgID", $$v)
                                  },
                                  expression: "host.orgID"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.user.super
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dense: "",
                                      label: "Super Admin",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.host.super,
                                      callback: function($$v) {
                                        _vm.$set(_vm.host, "super", $$v)
                                      },
                                      expression: "host.super"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.adding
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        autocomplete: "new-password",
                                        type: "password",
                                        placeholder: "PASSWORD",
                                        required: "",
                                        minlength: "8"
                                      },
                                      model: {
                                        value: _vm.password,
                                        callback: function($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        autocomplete: "new-password",
                                        type: "password",
                                        placeholder: "CONFIRM PASSWORD",
                                        required: "",
                                        minlength: "8"
                                      },
                                      model: {
                                        value: _vm.confirmPassword,
                                        callback: function($$v) {
                                          _vm.confirmPassword = $$v
                                        },
                                        expression: "confirmPassword"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.adding && !_vm.passwordMatch
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-layout", { attrs: { "justify-center": "" } }, [
                            _vm._v(" PASSWORD IS REQUIRED ")
                          ])
                        ],
                        1
                      )
                    : _vm.adding && !_vm.host.orgID
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-layout", { attrs: { "justify-center": "" } }, [
                            _vm._v(" ORG ID IS REQUIRED ")
                          ])
                        ],
                        1
                      )
                    : _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { disabled: _vm.working, flat: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v(" Save ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: { disabled: _vm.working, flat: "" },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }